import {MC_Device} from "../../../common/iot/MC_Device";
import ChillerSettings from "./ChillerSettings";
import {ChillerConstants} from "./ChillerConstants";

export interface ChillerAdminSettings {

}
export class Chiller extends MC_Device {

    // Action constants
    public static ACTION_EDIT_BAUD: string = "edit_baud";
    public static ACTION_EDIT_SELF_MSTP: string = "edit_self_mstp";
    public static ACTION_EDIT_CHILLER_MSTP: string = "edit_chiller_mstp";
    public static ACTION_EDIT_SETPOINT: string = "edit_setpoint";

    // Get admin setting value, or default
    public getAdminSetting(field: string, defaultValue: string): string {
        if (this.adminSettings != null && this.adminSettings.hasOwnProperty(field)) {
            const foundValue: string = this.adminSettings[field];
            if (foundValue.length > 0 || foundValue !== " ") {
                return foundValue;
            }
        }
        return defaultValue;
    }

    getSettingsUI(): JSX.Element | null {
        return <ChillerSettings chiller={this} />;
    }

    public getRecentVal(param: string): number | null {
        if (this.recent != null && this.recent.hasOwnProperty(param)) {
            return this.recent[param];
        }
        return null;
    }

    // True if the value has data
    public isEnumValueInUse(param: string): boolean {
        if (this.recent != null && this.recent.hasOwnProperty(param)) {
            const val: number | null = this.recent[param];
            if (val != null && !isNaN(val) && (val === 0 || val === 1)) {
                return true;
            }
        }
        return false;
    }

    // True if the value has data
    public isAnalogValueInUse(param: string): boolean {
        if (this.recent != null && this.recent.hasOwnProperty(param)) {
            const val: number | null = this.recent[param];
            if (val != null && !isNaN(val) && (val < 404 || val > 405)) {
                return true;
            }
        }
        return false;
    }

    public getMiscANIParams(): string[] {
        return [
            ChillerConstants.PARAM_MANI_1,
            ChillerConstants.PARAM_MANI_2,
            ChillerConstants.PARAM_MANI_3,
            ChillerConstants.PARAM_MANI_4,
            ChillerConstants.PARAM_MANI_5,
            ChillerConstants.PARAM_MANI_6,
            ChillerConstants.PARAM_MANI_7,
            ChillerConstants.PARAM_MANI_8
        ];
    }

    public getMiscENIParams(): string[] {
        return [
            ChillerConstants.PARAM_MENI_1,
            ChillerConstants.PARAM_MENI_2,
        ];
    }

    public getMiscANOParams(): string[] {
        return [
            ChillerConstants.PARAM_MANO_1,
            ChillerConstants.PARAM_MANO_2,
            ChillerConstants.PARAM_MANO_3,
            ChillerConstants.PARAM_MANO_4,
            ChillerConstants.PARAM_MANO_5,
            ChillerConstants.PARAM_MANO_6,
            ChillerConstants.PARAM_MANO_7,
            ChillerConstants.PARAM_MANO_8
        ];
    }

    public getMiscENOParams(): string[] {
        return [
            ChillerConstants.PARAM_MENO_1,
            ChillerConstants.PARAM_MENO_2
        ];
    }

    public getMiscAlarmParams(): string[] {
        return [
            ChillerConstants.PARAM_MISC_ALARM_1,
            ChillerConstants.PARAM_MISC_ALARM_2
        ];
    }

    // Returns misc ani names
    public getMiscANINames(defaultsOnly: boolean = false): string[] {
        const params: string[] = this.getMiscANIParams();
        return params.map((x: string, idx: number) => {
            const defaultName: string = "Misc. Analog Input " + (idx + 1);
            if (defaultsOnly) {
                return defaultName;
            }
            return this.getAdminSetting(x, defaultName);
        });
    }

    // Returns misc ani units
    public getMiscANIUnits(): string[] {
        const params: string[] = this.getMiscANIParams();
        return params.map((x: string, idx: number) => {
            const rawParam: string = x + "_unit";
            return this.getAdminSetting(rawParam, "");
        });
    }

    // Returns misc eni names
    public getMiscENINames(defaultsOnly: boolean = false): string[] {
        const params: string[] = this.getMiscENIParams();
        return params.map((x: string, idx: number) => {
            const defaultName: string = "Misc. Enum Input " + (idx + 1);
            if (defaultsOnly) {
                return defaultName;
            }
            return this.getAdminSetting(x, defaultName);
        });
    }

    // Returns misc ano names
    public getMiscANONames(defaultsOnly: boolean = false): string[] {
        const params: string[] = this.getMiscANOParams();
        return params.map((x: string, idx: number) => {
            const defaultName: string = "Misc. Analog Output " + (idx + 1);
            if (defaultsOnly) {
                return defaultName;
            }
            return this.getAdminSetting(x, defaultName);
        });
    }

    // Returns misc ano units
    public getMiscANOUnits(): string[] {
        const params: string[] = this.getMiscANOParams();
        return params.map((x: string, idx: number) => {
            const rawParam: string = x + "_unit";
            return this.getAdminSetting(rawParam, "");
        });
    }

    // Returns misc eno names
    public getMiscENONames(defaultsOnly: boolean = false): string[] {
        const params: string[] = this.getMiscENOParams();
        return params.map((x: string, idx: number) => {
            const defaultName: string = "Misc. Enum Output " + (idx + 1);
            if (defaultsOnly) {
                return defaultName;
            }
            return this.getAdminSetting(x, defaultName);
        });
    }

    // Returns misc alarm names
    public getMiscAlarmNames(defaultsOnly: boolean = false): string[] {
        const params: string[] = this.getMiscAlarmParams();
        return params.map((x: string, idx: number) => {
            const defaultName: string = "Misc. Alarm " + (idx + 1);
            if (defaultsOnly) {
                return defaultName;
            }
            return this.getAdminSetting(x, defaultName);
        });
    }

    // Get module mstp addr
    public getModuleMSTPAddr(): number | null {
        return this.getRecentVal(ChillerConstants.PARAM_SELF_ADDR);
    }

    // Get chiller mstp addr
    public getChillerMSTPAddr(): number | null {
        return this.getRecentVal(ChillerConstants.PARAM_CHILLER_ADDR);
    }

}