
import React from "react";

function ChillerBlueIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    version="1.1"
    viewBox="0 0 682.667 682.667"
    xmlSpace="preserve"
    >
    <defs>
        <clipPath id="clipPath2477" clipPathUnits="userSpaceOnUse">
    <path d="M0 512h512V0H0z"></path>
        </clipPath>
        </defs>
        <g transform="matrix(1.33333 0 0 -1.33333 0 682.667)">
    <g>
        <g clipPath="url(#clipPath2477)">
    <path
        fill="none"
    stroke="#0D2444"
    strokeDasharray="none"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    strokeMiterlimit="10"
    strokeOpacity="1"
    strokeWidth="30"
    d="M195 76H15v240h180z"
        ></path>
        <g transform="translate(195 196)">
    <path
        fill="none"
    stroke="#0D2444"
    strokeDasharray="none"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    strokeMiterlimit="10"
    strokeOpacity="1"
    strokeWidth="30"
    d="M0 0h180c33.137 0 60-26.863 60-60s-26.863-60-60-60H0"
        ></path>
        </g>
        <g transform="translate(135 196)">
    <path
        fill="none"
    stroke="#0D2444"
    strokeDasharray="none"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    strokeMiterlimit="10"
    strokeOpacity="1"
    strokeWidth="30"
    d="M0 0h-60"
        ></path>
        </g>
        <path
    fill="none"
    stroke="#0D2444"
    strokeDasharray="none"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    strokeMiterlimit="10"
    strokeOpacity="1"
    strokeWidth="30"
    d="M135 136H75v120h60z"
        ></path>
        <g transform="translate(75 316)">
    <path
        fill="none"
    stroke="#0D2444"
    strokeDasharray="none"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    strokeMiterlimit="10"
    strokeOpacity="1"
    strokeWidth="30"
    d="M0 0v60c0 49.629 40.371 90 90 90h90V90H90c-16.538 0-30-13.462-30-30V0"
        ></path>
        </g>
        <g transform="translate(195 406)">
    <path
        fill="none"
    stroke="#0D2444"
    strokeDasharray="none"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    strokeMiterlimit="10"
    strokeOpacity="1"
    strokeWidth="30"
    d="M0 0v60"
        ></path>
        </g>
        <g transform="translate(345 256)">
    <path
        fill="none"
    stroke="#0D2444"
    strokeDasharray="none"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    strokeMiterlimit="10"
    strokeOpacity="1"
    strokeWidth="30"
    d="M0 0h-150v60H0c16.568 0 30-13.432 30-30C30 13.432 16.568 0 0 0z"
        ></path>
        </g>
        <g transform="translate(375 286)">
    <path
        fill="none"
    stroke="#0D2444"
    strokeDasharray="none"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    strokeMiterlimit="10"
    strokeOpacity="1"
    strokeWidth="30"
    d="M0 0h122v-150H59.999"
        ></path>
        </g>
        <g transform="translate(75 31)">
    <path
        fill="none"
    stroke="#0D2444"
    strokeDasharray="none"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    strokeMiterlimit="10"
    strokeOpacity="1"
    strokeWidth="30"
    d="M0 0v45"
        ></path>
        </g>
        <g transform="translate(345 31)">
    <path
        fill="none"
    stroke="#0D2444"
    strokeDasharray="none"
    strokeLinecap="butt"
    strokeLinejoin="miter"
    strokeMiterlimit="10"
    strokeOpacity="1"
    strokeWidth="30"
    d="M0 0v45"
        ></path>
        </g>
        </g>
        </g>
        </g>
        </svg>
);
}

export default ChillerBlueIcon;
