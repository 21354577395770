
export class ChillerConstants {

    // --- Inputs ---
    public static PARAM_ALARM_RESET: string = "alarm_reset"; // 0: Off 1: Reset
    //-
    public static PARAM_COMP_PID_INTEGRAL: string = "comp_pid_integral";
    public static PARAM_COMP_PID_INTERVAL: string = "comp_pid_interval";
    public static PARAM_COMP_PID_PBAND: string = "comp_pid_pband";
    public static PARAM_COMP_PUMP_DOWNTIME: string = "comp_pdt";
    //-
    public static PARAM_DISC_PRESSURE_SP: string = "disc_pressure_sp";
    public static PARAM_DISC_PRESSURE_WARNING_SP: string = "disc_pressure_wsp";
    //-
    public static PARAM_FAN_PID_INTEGRAL: string = "fan_pid_integral";
    public static PARAM_FAN_PID_INTERVAL: string = "fan_pid_interval";
    public static PARAM_FAN_PID_PBAND: string = "fan_pid_pband";
    //-
    public static PARAM_FLOW_WARNING_SP: string = "flow_wsp";
    //-
    public static PARAM_MIN_FAN_SPEED: string = "min_fan_speed";
    public static PARAM_MIN_PROCESS_FLOW: string = "min_process_flow";
    public static PARAM_MIN_PUMP_SPEED: string = "min_pump_speed";
    public static PARAM_MIN_TEMP_SP: string = "min_temp_sp";
    //-
    // Misc analog inputs & enum inputs
    // public static PARAM_MISC_ANI_1_8: string = "misc_ani_1_8";
    // public static PARAM_MISC_ENI_1_2: string = "misc_eni_1_2"; // 0: Off 1: On
    // Map to each own param
    public static PARAM_MANI_1: string = "mani_1";
    public static PARAM_MANI_2: string = "mani_2";
    public static PARAM_MANI_3: string = "mani_3";
    public static PARAM_MANI_4: string = "mani_4";
    public static PARAM_MANI_5: string = "mani_5";
    public static PARAM_MANI_6: string = "mani_6";
    public static PARAM_MANI_7: string = "mani_7";
    public static PARAM_MANI_8: string = "mani_8";
    public static PARAM_MENI_1: string = "meni_1";
    public static PARAM_MENI_2: string = "meni_2";
    //-
    public static PARAM_PID4_INTEGRAL: string = "pid4_integral";
    public static PARAM_PID4_INTERVAL: string = "pid4_interval";
    public static PARAM_PID4_PBAND: string = "pid4_pband";
    public static PARAM_PID5_INTEGRAL: string = "pid5_integral";
    public static PARAM_PID5_INTERVAL: string = "pid5_interval";
    public static PARAM_PID5_PBAND: string = "pid5_pband";
    public static PARAM_PID6_INTEGRAL: string = "pid6_integral";
    public static PARAM_PID6_INTERVAL: string = "pid6_interval";
    public static PARAM_PID6_PBAND: string = "pid6_pband";
    //-
    public static PARAM_PUMP_PID_INTEGRAL: string = "pump_pid_integral";
    public static PARAM_PUMP_PID_INTERVAL: string = "pump_pid_interval";
    public static PARAM_PUMP_PID_PBAND: string = "pump_pid_pband";
    public static PARAM_PUMP_DIFF_P_SP: string = "pump_diff_p_sp";
    //-
    public static PARAM_SUPPLY_TEMP_SP: string = "supply_temp_sp";
    //-
    public static PARAM_SYSTEM_ENABLE: string = "system_enable"; // 0: Disable 1: Enable
    //-
    public static PARAM_TEMP_WARNING_SP: string = "temp_wsp";
    // ----- ----- -----

    // --- Outputs ---
    public static PARAM_CHILLER_TONNAGE: string = "chiller_tonnage";
    //-
    public static PARAM_CIRC1_DISC_PRESSURE: string = "circ1_disc_pressure";
    public static PARAM_CIRC1_DISC_TEMP: string = "circ1_disc_temp";
    public static PARAM_CIRC1_OIL_ALARM: string = "circ1_oil_alarm"; // 0: Normal 1: Alarm
    public static PARAM_CIRC2_DISC_PRESSURE: string = "circ2_disc_pressure";
    public static PARAM_CIRC2_DISC_TEMP: string = "circ2_disc_temp";
    public static PARAM_CIRC2_OIL_ALARM: string = "circ2_oil_alarm"; // 0: Normal 1: Alarm
    public static PARAM_CIRC3_DISC_PRESSURE: string = "circ3_disc_pressure";
    public static PARAM_CIRC3_DISC_TEMP: string = "circ3_disc_temp";
    public static PARAM_CIRC3_OIL_ALARM: string = "circ3_oil_alarm"; // 0: Normal 1: Alarm
    public static PARAM_CIRC4_DISC_PRESSURE: string = "circ4_disc_pressure";
    public static PARAM_CIRC4_DISC_TEMP: string = "circ4_disc_temp";
    public static PARAM_CIRC4_OIL_ALARM: string = "circ4_oil_alarm"; // 0: Normal 1: Alarm
    //-
    public static PARAM_COMP1A_STATUS: string = "comp1a_status"; // 0: Off 1: On
    public static PARAM_COMP1B_STATUS: string = "comp1b_status"; // 0: Off 1: On
    public static PARAM_COMP1C_STATUS: string = "comp1c_status"; // 0: Off 1: On
    public static PARAM_COMP2A_STATUS: string = "comp2a_status"; // 0: Off 1: On
    public static PARAM_COMP2B_STATUS: string = "comp2b_status"; // 0: Off 1: On
    public static PARAM_COMP2C_STATUS: string = "comp2c_status"; // 0: Off 1: On
    //-
    public static PARAM_COMP_PID_OUTPUT: string = "comp_pid_output";
    //-
    public static PARAM_DIRTY_FILTER_ALARM: string = "dfilter_alarm"; // 0: Normal 1: Alarm
    public static PARAM_DIRTY_FILTER_WARNING: string = "dfilter_warning"; // 0: Normal 1: Warning
    public static PARAM_DISC_PRESSURE_ALARM: string = "disc_p_alarm"; // 0: Normal 1: Alarm
    public static PARAM_DISC_PRESSURE_WARNING: string = "disc_p_warning"; // 0: Normal 1: Warning
    //-
    public static PARAM_EVAP1_LEAVING_TEMP: string = "evap1_ltemp";
    public static PARAM_EVAP1_TONNAGE: string = "evap1_tonnage";
    public static PARAM_EVAP2_LEAVING_TEMP: string = "evap2_ltemp";
    public static PARAM_EVAP2_TONNAGE: string = "evap2_tonnage";
    //-
    public static PARAM_FAN_ALARM: string = "fan_alarm"; // 0: Normal 1: Alarm
    public static PARAM_FAN_PID_OUTPUT: string = "fan_pid_output";
    public static PARAM_FAN_SPEED: string = "fan_speed";
    //-
    public static PARAM_GENERAL_ALARM: string = "gen_alarm"; // 0: Normal 1: Alarm
    public static PARAM_GENERAL_WARNING: string = "gen_warning"; // 0: Normal 1: Warning
    //-
    public static PARAM_LOAD_TONNAGE: string = "load_tonnage";
    //-
    public static PARAM_LOW_FLOW_ALARM: string = "low_flow_alarm"; // 0: Normal 1: Alarm
    public static PARAM_LOW_FLOW_WARNING: string = "low_flow_warning"; // 0: Normal 1: Warning
    public static PARAM_LOW_TEMP_WARNING: string = "low_temp_warning"; // 0: Normal 1: Warning
    public static PARAM_HIGH_TEMP_ALARM: string = "high_temp_alarm"; // 0: Normal 1: Alarm
    public static PARAM_SPRAY_PUMP_ALARM: string = "spray_pump_alarm"; // 0: Normal 1: Alarm
    //-
    // Misc outputs
    //public static PARAM_MISC_ALARM_1_2: string = "misc_alarm_1_2"; // 0: Normal 1: Alarm
    ///public static PARAM_MISC_ANO_1_8: string = "misc_ano_1_8";
    //public static PARAM_MISC_ENO_1_2: string = "misc_eno_1_2"; // 0: Off 1: On
    // Map to each own param
    public static PARAM_MISC_ALARM_1: string = "misc_alarm_1";
    public static PARAM_MISC_ALARM_2: string = "misc_alarm_2";
    public static PARAM_MANO_1: string = "mano_1";
    public static PARAM_MANO_2: string = "mano_2";
    public static PARAM_MANO_3: string = "mano_3";
    public static PARAM_MANO_4: string = "mano_4";
    public static PARAM_MANO_5: string = "mano_5";
    public static PARAM_MANO_6: string = "mano_6";
    public static PARAM_MANO_7: string = "mano_7";
    public static PARAM_MANO_8: string = "mano_8";
    public static PARAM_MENO_1: string = "meno_1";
    public static PARAM_MENO_2: string = "meno_2";
    //-
    public static PARAM_MISC_TEMP: string = "misc_temp";
    public static PARAM_AIR_TEMP: string = "air_temp";
    //-
    public static PARAM_PID4_OUTPUT: string = "pid4_output";
    public static PARAM_PID5_OUTPUT: string = "pid5_output";
    public static PARAM_PID6_OUTPUT: string = "pid6_output";
    public static PARAM_PUMP_PID_OUTPUT: string = "pump_pid_output";
    //-
    public static PARAM_PUMP1_ALARM: string = "pump1_alarm"; // 0: Normal 1: Alarm
    public static PARAM_PUMP1_SPEED: string = "pump1_speed";
    public static PARAM_PUMP1_STATUS: string = "pump1_status"; // 0: Off 1: On
    public static PARAM_PUMP2_ALARM: string = "pump2_alarm"; // 0: Normal 1: Alarm
    public static PARAM_PUMP2_SPEED: string = "pump2_speed";
    public static PARAM_PUMP2_STATUS: string = "pump2_status"; // 0: Off 1: On
    //-
    public static PARAM_RETURN_TEMP: string = "return_temp";
    public static PARAM_SECONDARY_FLOW_RATE: string = "second_flow";
    public static PARAM_SPRAY_PUMP_STATUS: string = "spray_pump_status"; // 0: Off 1: On
    public static PARAM_SUPPLY_TEMP: string = "supply_temp";
    public static PARAM_SYSTEM_DIFF_PRESSURE: string = "sys_diff_p";
    public static PARAM_SYSTEM_FLOW_RATE: string = "sys_flow";
    //-
    public static PARAM_TANK_FULL: string = "tank_full"; // 0: Low 1: Full
    public static PARAM_TANK_LOW_ALARM: string = "tank_low_alarm"; // 0: Normal 1: Alarm
    public static PARAM_TANK_LOW_WARNING: string = "tank_low_warning"; // 0: Normal 1: Warning
    public static PARAM_TEMP_TOLERANCE_WARNING: string = "temp_tol_warning"; // 0: Normal 1: Warning
    // ----- ----- -----

    // --- Other ---
    public static PARAM_DATA_IO_FLAG: string = "in"; // in=1 means inputs data (0 means outputs)
    public static PARAM_BAUD_RATE: string = "baud";
    public static PARAM_SELF_ADDR: string = "self_addr";
    public static PARAM_CHILLER_ADDR: string = "chiller_addr";
    public static PARAM_BYTES_RECEIVED: string = "br";
    public static PARAM_MSTP_TOKEN_SOURCE: string = "token_src";
    public static PARAM_DATA_ERROR: string = "err";
    public static PARAM_POST_REASON: string = "r";
    public static PARAM_MODULE_VERSION: string = "v";
    public static PARAM_SIGNAL: string = "signal";
    // ----- ----- -----

    // Remote chiller writing access
    public static PARAM_WRITE_PARAM: string = "w_param";
    public static PARAM_WRITE_VALUE: string = "w_val";
    public static PARAM_WRITE_STATUS: string = "w_status";

}