import "./ViewDevice.css";
import {Box, Tab} from "@mui/material";
import {MC_Backend} from "../../../common/MC_Backend";
import {useNavigate, useParams} from "react-router-dom";
import {MC_Device} from "../../../common/iot/MC_Device";
import React, {useContext} from "react";
import GeneralHeader from "../../../common/ui/GeneralHeader";
import MCLoadingBar from "../../../common/ui/misc/MCLoadingBar";
import ViewOrganizations from "../../../common/ui/view-section/ViewOrganizations";
import ViewActivityLog from "../../../common/ui/view-section/ViewActivityLog";
import ViewDeviceAlerts from "../../../common/ui/view-section/ViewDeviceAlerts";
import {MC_Action_Log} from "../../../common/iot/MC_Action_Log";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import AddOrgToDeviceDialog from "../../../common/ui/dialog/AddDialog/AddOrgToDeviceDialog";
import {MC_Organization} from "../../../common/iot/MC_Organization";
import RemoveDeviceFromOrgDialog from "../../../common/ui/dialog/RemoveDialog/RemoveDeviceFromOrgDialog";
import DeleteDeviceDialog from "../../../common/ui/dialog/DeleteDialog/DeleteDeviceDialog";
import {DevicesContext} from "../../../App";
import DeviceInfoTab from "./DeviceInfoTab";
import DeviceLicenseTab from "./DeviceLicenseTab";

interface ViewDeviceState {
    refreshing: boolean;
    errMsg: string | null;
}
interface RemoveOrgState {
    open: boolean;
    org: MC_Organization | null;
}
function ViewDevice(props: any) {
    const TAB_INFO: string = "info";
    const TAB_SETTINGS: string = "settings";
    const TAB_LICENSE: string = "license";
    const TAB_ORGS: string = "organizations";
    const TAB_RECENT_ALERTS: string = "recent_alerts";
    const TAB_ACTIVITY_LOG: string = "activity_log";
    const nav = useNavigate();
    const ctx = useContext(DevicesContext);

    // State
    const [curState, setCurState] = React.useState<ViewDeviceState>({refreshing: false, errMsg: null});
    const [deleteDeviceOpen, setDeleteDeviceOpen] = React.useState<boolean>(false);
    const [addOrgOpen, setAddOrgOpen] = React.useState<boolean>(false);
    const [removeOrgState, setRemoveOrgState] = React.useState<RemoveOrgState>({open: false, org: null});
    const [tabValue, setTabValue] = React.useState<string>(TAB_INFO);

    // Load devices if they are not loading already
    React.useEffect(() => {
        if (ctx.devices == null && !ctx.loading && ctx.errMsg == null) {
            MC_Backend.getInstance().loadDevices().finally();
        }
    }, [ctx.loading, ctx.devices, ctx.errMsg]);

    // Parse Device ID
    const {deviceID} = useParams();
    const deviceIDStr: string | null = (deviceID != null) ? deviceID as string : null;
    if (deviceIDStr == null) {
        return (<p className={"bad"}>Missing Device ID</p>);
    }

    // Helper fns
    let refreshDeviceFn = () => {
        if (!curState.refreshing) {
            setCurState({refreshing: true, errMsg: null});
            MC_Backend.getInstance().loadDevice(deviceIDStr)
                .then(() => setCurState({refreshing: false, errMsg: null}))
                .catch((e) => setCurState({refreshing: false, errMsg: e as string}))
            ;
            // Also load overview (logs) if that is the tab
            if (tabValue === TAB_ACTIVITY_LOG) {
                MC_Backend.getInstance().loadOverview();
            }
        }
    };
    const goBackFn = () => {
        nav("/core/devices");
    };

    // UI
    return (
        <DevicesContext.Consumer>
            {
                (devicesCtx) => {
                    // Parse device by ID
                    const expectingNonNullDevice = (devicesCtx.devices != null);
                    const device: MC_Device | null = MC_Backend.getInstance().findDeviceByID(deviceIDStr);
                    // Helper vars
                    const isLoading = curState.refreshing || devicesCtx.loading;
                    const loadingError: string | null = (curState.errMsg != null) ? curState.errMsg : devicesCtx.errMsg;
                    // Optional settings
                    let settingsUI: JSX.Element | null = null;
                    if (device != null) {
                        settingsUI = device.getSettingsUI();
                    }
                    const settingsTabOffset: number = (settingsUI != null) ? 1 : 0; // 0 or 1 depending on settings ui

                    // UI
                    return (
                        <div className={"view-device-root"}>

                            {/* Dialogs */}
                            <React.Fragment>
                                {/* Delete device dialog */}
                                <DeleteDeviceDialog
                                    open={deleteDeviceOpen}
                                    device={device}
                                    closeDialogFn={() => setDeleteDeviceOpen(false)}
                                    doneSuccessFn={goBackFn}
                                    doneFailureFn={() => {}}
                                />
                                {/* Add org to device dialog */}
                                <AddOrgToDeviceDialog
                                    open={addOrgOpen}
                                    device={device}
                                    closeDialogFn={() => setAddOrgOpen(false)}
                                    doneSuccessFn={refreshDeviceFn}
                                    doneFailureFn={() => {}}
                                />
                                {/* Remove org from device dialog */}
                                <RemoveDeviceFromOrgDialog
                                    open={removeOrgState.open}
                                    device={device}
                                    org={removeOrgState.org}
                                    closeDialogFn={() => setRemoveOrgState({open: false, org: removeOrgState.org})}
                                    doneSuccessFn={refreshDeviceFn}
                                    doneFailureFn={() => {}}
                                />
                            </React.Fragment>

                            {/* Header */}
                            <GeneralHeader
                                title={(device != null) ? device.readableJASID : "Loading..."}
                                subtitle={(device != null) ? device.name : undefined}
                                icon={"device"}
                                backAction={goBackFn}
                                refreshAction={(device != null) ? refreshDeviceFn : () => {}}
                            />

                            {/* Refresh indicator */}
                            <MCLoadingBar loadingMessage={"Refreshing..."} loading={isLoading} errorMessage={loadingError}/>

                            {/* Show error if there is no device, but there should be */}
                            {
                                (expectingNonNullDevice && device == null) &&
                                <p className={"error-note"}>Could not find device with ID: {deviceIDStr}</p>
                            }

                            {/* Show profile if device is loaded */}
                            {
                                (device != null && devicesCtx.refreshTS != null) &&
                                <React.Fragment>

                                    <div className={"content-div"}>

                                        {/* Tab content */}
                                        <TabContext value={tabValue}>

                                            {/* Tab header */}
                                            <Box className={"tablistbox"}>
                                                <TabList
                                                    variant={"scrollable"} allowScrollButtonsMobile={true}
                                                    value={tabValue} textColor={"primary"}
                                                    onChange={(e, v: string) => setTabValue(v)}>
                                                    <Tab value={TAB_INFO} label="Info" />
                                                    {settingsUI != null && <Tab value={TAB_SETTINGS} label="Settings"/>}
                                                    <Tab value={TAB_LICENSE} label="License" />
                                                    <Tab value={TAB_ORGS} label="Organizations" />
                                                    <Tab value={TAB_RECENT_ALERTS} label="Recent Alerts" />
                                                    <Tab value={TAB_ACTIVITY_LOG} label="Activity" />
                                                </TabList>
                                            </Box>

                                            {/* Info tab */}
                                            <TabPanel value={TAB_INFO} tabIndex={0}>
                                                <DeviceInfoTab device={device}
                                                               refreshTS={devicesCtx.refreshTS}
                                                               setDeleteDeviceOpen={setDeleteDeviceOpen}>
                                                </DeviceInfoTab>
                                            </TabPanel>

                                            {/* Optional settings */}
                                            {settingsUI != null &&
                                                <TabPanel value={TAB_SETTINGS} tabIndex={1}>
                                                    {settingsUI}
                                                </TabPanel>
                                            }

                                            {/* License tab */}
                                            <TabPanel value={TAB_LICENSE} tabIndex={1 + settingsTabOffset}>
                                                <DeviceLicenseTab device={device}/>
                                            </TabPanel>

                                            {/* Organizations */}
                                            <TabPanel value={TAB_ORGS} tabIndex={2 + settingsTabOffset}>
                                                <ViewOrganizations
                                                    organizationIDs={device.organizationIDs}
                                                    desc={"This device is accessible to the organizations listed below:"}
                                                    addAction={() => setAddOrgOpen(true)}
                                                    removeAction={(o) => setRemoveOrgState({open: true, org: o})}
                                                />
                                            </TabPanel>

                                            {/* Recent alerts */}
                                            <TabPanel value={TAB_RECENT_ALERTS} tabIndex={3 + settingsTabOffset}>
                                                <ViewDeviceAlerts device={device}/>
                                            </TabPanel>

                                            {/* Activity log */}
                                            <TabPanel value={TAB_ACTIVITY_LOG} tabIndex={4 + settingsTabOffset}>
                                                <ViewActivityLog
                                                    pageSize={10}
                                                    title={"Activity Log"}
                                                    desc={"Here is recent activity relating to this device."}
                                                    logFilterFn={(x: MC_Action_Log) => x.device_ids.includes(device.id)}
                                                />
                                            </TabPanel>

                                        </TabContext>

                                    </div>

                                    <br/><br/><br/>

                                </React.Fragment>
                            }

                        </div>
                    );
                }
            }
        </DevicesContext.Consumer>
    );
}

export default ViewDevice;
