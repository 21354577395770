import "./ChillerSettings.css";
import {Chiller} from "./Chiller";
import {Alert, Button, Paper, Snackbar, TextField} from "@mui/material";
import React from "react";
import {MC_Backend} from "../../../common/MC_Backend";

interface CMVProps {
    inUse: boolean;
    isUnit: boolean;
    baseVal: string; // "Misc. Analog Output 1" etc
    val: string;
    setVal: (newName: string) => void;
}
function CustomizeMiscValue(props: CMVProps) {
    return <div className={"cmv-div"}>
        <TextField
            sx={{
                maxWidth: (props.isUnit) ? 80 : undefined
            }}
            variant="filled"
            label={props.baseVal}
            value={props.val}
            onChange={(ev) => props.setVal(ev.target.value)}
            helperText={(props.inUse) ? "In-Use" : "UNUSED"}
        />
    </div>;
}

export interface ChillerSettingsProps {
    chiller: Chiller;
}
function ChillerSettings(props: ChillerSettingsProps) {
    const chiller: Chiller = props.chiller;
    // Inputs
    const miscANIParams: string[] = chiller.getMiscANIParams();
    const miscENIParams: string[] = chiller.getMiscENIParams();
    const baseMiscANINames: string[] = chiller.getMiscANINames(true);
    const baseMiscENINames: string[] = chiller.getMiscENINames(true);
    const existingMiscANINames: string[] = chiller.getMiscANINames(false);
    const existingMiscANIUnits: string[] = chiller.getMiscANIUnits();
    const existingMiscENINames: string[] = chiller.getMiscENINames(false);
    // Outputs
    const miscANOParams: string[] = chiller.getMiscANOParams();
    const miscENOParams: string[] = chiller.getMiscENOParams();
    const baseMiscANONames: string[] = chiller.getMiscANONames(true);
    const baseMiscENONames: string[] = chiller.getMiscENONames(true);
    const existingMiscANONames: string[] = chiller.getMiscANONames(false);
    const existingMiscANOUnits: string[] = chiller.getMiscANOUnits();
    const existingMiscENONames: string[] = chiller.getMiscENONames(false);
    // Alarms
    const miscAlarmParams: string[] = chiller.getMiscAlarmParams();
    const baseMiscAlarmNames: string[] = chiller.getMiscAlarmNames(true);
    const existingMiscAlarmNames: string[] = chiller.getMiscAlarmNames(false);
    // MSTP
    const existingChillerAddr: number | null = chiller.getChillerMSTPAddr();
    const existingSelfAddr: number | null = chiller.getModuleMSTPAddr();
    const toStrFn = (val: number | null) => {
        return (val != null) ? val + "" : "";
    };
    // State
    // -Inputs-
    const [miscANINames, setMiscANINames] = React.useState<string[]>(existingMiscANINames);
    const [miscANIUnits, setMiscANIUnits] = React.useState<string[]>(existingMiscANIUnits);
    const [miscENINames, setMiscENINames] = React.useState<string[]>(existingMiscENINames);
    const [inputOpInProgress, setInputOpInProgress] = React.useState<boolean>(false);
    // -Outputs-
    const [miscANONames, setMiscANONames] = React.useState<string[]>(existingMiscANONames);
    const [miscANOUnits, setMiscANOUnits] = React.useState<string[]>(existingMiscANOUnits);
    const [miscENONames, setMiscENONames] = React.useState<string[]>(existingMiscENONames);
    const [outputOpInProgress, setOutputOpInProgress] = React.useState<boolean>(false);
    // -Alarms-
    const [miscAlarmNames, setMiscAlarmNames] = React.useState<string[]>(existingMiscAlarmNames);
    const [alarmsOpInProgress, setAlarmsOpInProgress] = React.useState<boolean>(false);
    // -Chiller MSTP-
    const [chillerAddr, setChillerAddr] = React.useState<string>(toStrFn(existingChillerAddr));
    const [chillOpInProgress, setChillOpInProgress] = React.useState<boolean>(false);
    // -Module MSTP-
    const [selfAddr, setSelfAddr] = React.useState<string>(toStrFn(existingSelfAddr));
    const [selfOpInProgress, setSelfOpInProgress] = React.useState<boolean>(false);
    // General
    const [progressSnackOpen, setProgressSnackOpen] = React.useState<boolean>(false);
    const [errorSnackOpen, setErrorSnackOpen] = React.useState<boolean>(false);
    const [snackErrMsg, setSnackErrMsg] = React.useState<string | null>(null);
    const [successSnackOpen, setSuccessSnackOpen] = React.useState<boolean>(false);
    const [snackSuccessMsg, setSnackSuccessMsg] = React.useState<string | null>(null);
    // Helper fns
    const updateInputsFn = () => {
        if (inputOpInProgress) {
            return;
        }
        setInputOpInProgress(true);
        setProgressSnackOpen(true);
        MC_Backend.getInstance().updateChillerMiscInputs(
            chiller, miscANINames, miscANIUnits, miscENINames
        ).then(() => {
            setSnackSuccessMsg("Updated Inputs.");
            setSuccessSnackOpen(true);
        }).catch((e) => {
            setSnackErrMsg("Error updating inputs: " + e);
            setErrorSnackOpen(true);
        }).finally(() => {
            setInputOpInProgress(false);
        });
    };
    const updateOutputsFn = () => {
        if (outputOpInProgress) {
            return;
        }
        setOutputOpInProgress(true);
        setProgressSnackOpen(true);
        MC_Backend.getInstance().updateChillerMiscOutputs(
            chiller, miscANONames, miscANOUnits, miscENONames
        ).then(() => {
            setSnackSuccessMsg("Updated Outputs.");
            setSuccessSnackOpen(true);
        }).catch((e) => {
            setSnackErrMsg("Error updating outputs: " + e);
            setErrorSnackOpen(true);
        }).finally(() => {
            setOutputOpInProgress(false);
        });
    };
    const updateAlarmsFn = () => {
        if (alarmsOpInProgress) {
            return;
        }
        setAlarmsOpInProgress(true);
        setProgressSnackOpen(true);
        MC_Backend.getInstance().updateChillerMiscAlarms(
            chiller, miscAlarmNames
        ).then(() => {
            setSnackSuccessMsg("Updated Alarms.");
            setSuccessSnackOpen(true);
        }).catch((e) => {
            setSnackErrMsg("Error updating alarms: " + e);
            setErrorSnackOpen(true);
        }).finally(() => {
            setAlarmsOpInProgress(false);
        });
    };
    const updateChillerAddrFn = () => {
        if (chillOpInProgress) {
            return;
        }
        setChillOpInProgress(true);
        setProgressSnackOpen(true);
        MC_Backend.getInstance().callDeviceRemoteAction(
            chiller.id,
            Chiller.ACTION_EDIT_CHILLER_MSTP,
            {value: parseInt(chillerAddr)}
        ).then(() => {
            setSnackSuccessMsg("Updated Chiller MS/TP Address.");
            setSuccessSnackOpen(true);
        }).catch((e) => {
            setSnackErrMsg("Error updating chiller MS/TP Address: " + e);
            setErrorSnackOpen(true);
        }).finally(() => {
            setChillOpInProgress(false);
        });
    };
    const updateSelfAddrFn = () => {
        if (selfOpInProgress) {
            return;
        }
        setSelfOpInProgress(true);
        setProgressSnackOpen(true);
        MC_Backend.getInstance().callDeviceRemoteAction(
            chiller.id,
            Chiller.ACTION_EDIT_SELF_MSTP,
            {value: parseInt(selfAddr)}
        ).then(() => {
            setSnackSuccessMsg("Updated Module MS/TP Address.");
            setSuccessSnackOpen(true);
        }).catch((e) => {
            setSnackErrMsg("Error updating module MS/TP Address: " + e);
            setErrorSnackOpen(true);
        }).finally(() => {
            setSelfOpInProgress(false);
        });
    };
    // UI
    return <div className={"cs-root"}>

        {/* Snack bars */}
        <Snackbar
            open={progressSnackOpen}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => setProgressSnackOpen(false)}>
            <Alert severity="info" sx={{ width: '100%' }}>
                Updating...
            </Alert>
        </Snackbar>
        <Snackbar
            open={errorSnackOpen}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => setErrorSnackOpen(false)}>
            <Alert severity="error" sx={{ width: '100%' }}>
                Error: {(snackErrMsg != null) ? snackErrMsg : "Unknown Error"}
            </Alert>
        </Snackbar>
        <Snackbar
            open={successSnackOpen}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            onClose={() => setSuccessSnackOpen(false)}>
            <Alert severity="success" sx={{ width: '100%' }}>
                {snackSuccessMsg}
            </Alert>
        </Snackbar>

        {/* Misc Inputs */}
        <Paper className={"content-center"} elevation={3}>
            <div className={"center-row"}>
                <span className={"subtitle"}>Edit Misc. Inputs</span>
            </div>
            <span className={"desc-span"}>Customize the name & unit of misc. inputs.</span>
            {miscANIParams.map((aniParam: string, idx) => {
                // Create row
                const inUse: boolean = chiller.isAnalogValueInUse(aniParam);
                const baseName: string = baseMiscANINames[idx];
                const name: string = miscANINames[idx];
                const unit: string = miscANIUnits[idx];
                const updateNameFn = (newName: string) => {
                    const newNames: string[] = miscANINames.map((x) => x); // Shallow copy
                    newNames[idx] = newName;
                    setMiscANINames(newNames);
                };
                const updateUnitFn = (newUnit: string) => {
                    const newUnits: string[] = miscANIUnits.map((x) => x); // Shallow copy
                    newUnits[idx] = newUnit;
                    setMiscANIUnits(newUnits);
                }
                return <div key={aniParam} className={"cmv-row"}>
                    <CustomizeMiscValue inUse={inUse} isUnit={false}
                                        baseVal={baseName} val={name}
                                        setVal={updateNameFn}
                    />
                    <CustomizeMiscValue inUse={inUse} isUnit={true}
                                        baseVal={"Unit"} val={unit}
                                        setVal={updateUnitFn}
                    />
                </div>;
            })}
            {miscENIParams.map((eniParam: string, idx: number) => {
                const inUse: boolean = chiller.isEnumValueInUse(eniParam);
                const baseName: string = baseMiscENINames[idx];
                const name: string = miscENINames[idx];
                const updateENIFn = (newName: string) => {
                    const newNames: string[] = miscENINames.map((x) => x); // Shallow copy
                    newNames[idx] = newName;
                    setMiscENINames(newNames);
                };
                return <div key={eniParam} className={"cmv-row"}>
                    <CustomizeMiscValue inUse={inUse} isUnit={false}
                                        baseVal={baseName} val={name}
                                        setVal={updateENIFn}
                    />
                </div>;
            })}
            <Button variant="contained" color={"primary"}
                    disabled={inputOpInProgress} onClick={updateInputsFn}>
                Update Inputs
            </Button>
        </Paper>
        <br/>

        {/* Misc Outputs */}
        <Paper className={"content-center"} elevation={3}>
            <div className={"center-row"}>
                <span className={"subtitle"}>Edit Misc. Outputs</span>
            </div>
            <span className={"desc-span"}>Customize the name & unit of misc. outputs.</span>
            {miscANOParams.map((anoParam: string, idx) => {
                // Create row
                const inUse: boolean = chiller.isAnalogValueInUse(anoParam);
                const baseName: string = baseMiscANONames[idx];
                const name: string = miscANONames[idx];
                const unit: string = miscANOUnits[idx];
                const updateNameFn = (newName: string) => {
                    const newNames: string[] = miscANONames.map((x) => x); // Shallow copy
                    newNames[idx] = newName;
                    setMiscANONames(newNames);
                };
                const updateUnitFn = (newUnit: string) => {
                    const newUnits: string[] = miscANOUnits.map((x) => x); // Shallow copy
                    newUnits[idx] = newUnit;
                    setMiscANOUnits(newUnits);
                }
                return <div key={anoParam} className={"cmv-row"}>
                    <CustomizeMiscValue inUse={inUse} isUnit={false}
                                        baseVal={baseName} val={name}
                                        setVal={updateNameFn}
                    />
                    <CustomizeMiscValue inUse={inUse} isUnit={true}
                                        baseVal={"Unit"} val={unit}
                                        setVal={updateUnitFn}
                    />
                </div>;
            })}
            {miscENOParams.map((enoParam: string, idx: number) => {
                const inUse: boolean = chiller.isEnumValueInUse(enoParam);
                const baseName: string = baseMiscENONames[idx];
                const name: string = miscENONames[idx];
                const updateENOFn = (newName: string) => {
                    const newNames: string[] = miscENONames.map((x) => x); // Shallow copy
                    newNames[idx] = newName;
                    setMiscENONames(newNames);
                };
                return <div key={enoParam} className={"cmv-row"}>
                    <CustomizeMiscValue inUse={inUse} isUnit={false}
                                        baseVal={baseName} val={name}
                                        setVal={updateENOFn}
                    />
                </div>;
            })}
            <Button variant="contained" color={"primary"}
                    disabled={outputOpInProgress} onClick={updateOutputsFn}>
                Update Outputs
            </Button>
        </Paper>
        <br/>

        {/* Misc Alarms */}
        <Paper className={"content-center"} elevation={3}>
            <div className={"center-row"}>
                <span className={"subtitle"}>Edit Misc. Alarms</span>
            </div>
            <span className={"desc-span"}>Customize the name of misc. alarms.</span>
            {miscAlarmParams.map((alarmParam: string, idx: number) => {
                const inUse: boolean = chiller.isEnumValueInUse(alarmParam);
                const baseName: string = baseMiscAlarmNames[idx];
                const name: string = miscAlarmNames[idx];
                const updateFn = (newName: string) => {
                    const newNames: string[] = miscAlarmNames.map((x) => x); // Shallow copy
                    newNames[idx] = newName;
                    setMiscAlarmNames(newNames);
                };
                return <div key={alarmParam} className={"cmv-row"}>
                    <CustomizeMiscValue inUse={inUse} isUnit={false}
                                        baseVal={baseName} val={name}
                                        setVal={updateFn}
                    />
                </div>;
            })}
            <Button variant="contained" color={"primary"}
                    disabled={alarmsOpInProgress} onClick={updateAlarmsFn}>
                Update Alarms
            </Button>
        </Paper>
        <br/>

        {/* Chiller MS/TP Addr */}
        <Paper className={"content-center"} elevation={3}>
            <div className={"center-row"}>
                <span className={"subtitle"}>Chiller MS/TP Address</span>
            </div>
            <span className={"desc-span"}>
                This is the MS/TP bus address of the chiller to collect data from.
                <br/>
                After updating, it may take a few seconds to take effect.
            </span>
            <div className={"cmv-div"}>
                <TextField
                    variant="filled"
                    sx={{maxWidth: 150}}
                    label={"Chiller Address"}
                    value={chillerAddr}
                    onChange={(ev) => setChillerAddr(ev.target.value)}
                    helperText={"Must be 4-127"}
                />
            </div>
            <Button variant="contained" color={"primary"}
                    disabled={chillOpInProgress} onClick={updateChillerAddrFn}>
                Update
            </Button>
        </Paper>
        <br/>

        {/* Module MS/TP Addr */}
        <Paper className={"content-center"} elevation={3}>
            <div className={"center-row"}>
                <span className={"subtitle"}>Module MS/TP Address</span>
            </div>
            <span className={"desc-span"}>
                This is the MS/TP bus address of the ThermoLink module.
                <br/>
                After updating, it may take a few seconds to take effect.
            </span>
            <div className={"cmv-div"}>
                <TextField
                    variant="filled"
                    sx={{maxWidth: 150}}
                    label={"Module Address"}
                    value={selfAddr}
                    onChange={(ev) => setSelfAddr(ev.target.value)}
                    helperText={"Must be 4-127"}
                />
            </div>
            <Button variant="contained" color={"primary"}
                    disabled={selfOpInProgress} onClick={updateSelfAddrFn}>
                Update
            </Button>
        </Paper>
        <br/>

    </div>;
}

export default ChillerSettings;